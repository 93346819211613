.frametemplates-frametemplates {
  gap: 24px;
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
}

.frametemplates-textarea-container {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  gap: 12px;
}

.frametemplates-textarea {
  flex: 1;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  resize: none;
  outline: none;
}

.frametemplates-textarea:hover {
  opacity: 0.8;
}

.frametemplates-textarea:focus {
  opacity: 1;
}

.frametemplates-textarea::placeholder {
  opacity: 0.6;
  color: var(--dl-color-halo-textdark);
}

.frametemplates-button-container {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.frametemplates-input {
  padding: 1px;
  width: 100%;
  outline: none;
  opacity: 0.6;
  margin-bottom: 12px;
}

.frametemplates-input:hover {
  opacity: 0.8;
}

.frametemplates-input:focus {
  opacity: 1;
}

@media (max-width: 740px) {
  .frametemplates-frametemplates {
    padding: 20px;
    flex: 1;
  }
}
