.templateitem-templateitem {
  gap: 16px;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  transition: 0.3s;
  align-items: center;
  padding-top: 20px;
  padding-left: 12px;
  border-radius: 8px;
  padding-right: 12px;
  flex-direction: row;
  padding-bottom: 20px;
  justify-content: flex-start;
}

.templateitem-templateitem:hover {
  cursor: pointer;
  background-color: var(--dl-color-halo-hovercolorlight);
}

.templateitem-textcontainer {
  gap: 4px;
  flex: 4;
  width: 80%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.templateitem-text1 {
  font-size: 12px;
  align-content: vertical;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
}

.templateitem-visitusecontainer {
  gap: 6px;
  flex: 1;
  width: 20%;
  height: auto;
  display: none;
  opacity: 0.3;
  align-items: center;
  flex-direction: row;
}

.templateitem-image {
  width: 13px;
  height: 13px;
  object-fit: cover;
}

.templateitem-text2 {
  font-size: 12px;
}

.templateitem-buttoncontainer {
  gap: 10px;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}

.templateitem-statecontainer {
  gap: 4px;
  flex: 1;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.templateitem-statelabel {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: 4px;
  border-width: 1px;
  padding-left: 8px;
  border-radius: 8px;
  padding-right: 8px;
  padding-bottom: 4px;
  margin-right: 24px;
}
.templateitem-text3 {
  font-size: 12px;
}

.templateitem-templateitem-default:hover {
  background-color: white;
  cursor: default;
}
.templateitem-templateitem-default {
  /* pointer-events: none;
  cursor: not-allowed; */
}

.templateitem-notvisible-button {
  opacity: 0;
  cursor: none;
  pointer-events: none;
}

@media (max-width: 740px) {
  .templateitem-buttoncontainer {
    gap: 0px;
  }
}

.circleiconlight-root-class-selected {
  background-color: white;
}

.circleiconlight-root-class-unselected {
  background-color: white;
}

.circleiconlight-root-class-selected:hover {
  background-color: white;
}

.circleiconlight-root-class-unselected:hover {
  background-color: white;
}

.circleiconlight-root-class-selected img {
  /* Normal behavior for selected */
  opacity: 1;
}

.circleiconlight-root-class-unselected img {
  /* Unselected state makes the image invisible */
  opacity: 0;
}

.circleiconlight-root-class-unselected:hover img {
  /* Hover effect for unselected makes the image slightly visible */
  opacity: 0.3;
}
