.pausedbutton-pausedbutton {
  gap: 10px;
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: center;
  padding-top: 14px;
  border-color: #fdbe1d;
  border-width: 1px;
  padding-left: 14px;
  border-radius: 50px;
  padding-right: 14px;
  padding-bottom: 14px;
  justify-content: center;
}
.pausedbutton-pausedbutton:hover {
  cursor: pointer;
  background-color: #fcfaf5;
}
.pausedbutton-text {
  fill: #fdbe1d;
  color: rgb(253, 190, 29);
}
