.languagetypecontainer-languagetypecontainer {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
}

.languagetypecontainer-text {
  width: auto;
}

.zero {
  width: 0px;
  height: 0px;
}

.languagetypecontainer-dropdown {
  position: absolute;
  top: 0px;
  left: 40px;
}

/* Additional styles for better dropdown positioning */
.languagetypecontainer-languagetypecontainer {
  position: relative;
}

/* Style for the SubButton component */
.sub-button {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background-color: var(--dl-color-background);
  border: 1px solid var(--dl-color-halo-bordercolor);
  border-radius: 8px;
  cursor: pointer;
}

.sub-button:hover {
  background-color: var(--dl-color-background-hover);
}

.sub-button img {
  margin-left: 8px;
}

/* Disabled button style */
.disabled-button {
  pointer-events: none;
  opacity: 0.5;
}
