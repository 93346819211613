.visitnamecontainer-visitnamecontainer {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
}

.visitnamecontainer-visitname {
  gap: 10px;
  width: auto;
  height: auto;
  display: flex;
  opacity: 0.3;
  padding: 8px;
  transition: 0.3s;
  align-items: center;
  border-radius: 8px;
  justify-content: flex-start;
}

.visitnamecontainer-visitname:hover {
  cursor: pointer;
  opacity: 0.5;
  background-color: var(--dl-color-halo-hovercolor);
}

.visitnamecontainer-name {
  fill: var(--dl-color-halo-textdark);
  color: var(--dl-color-halo-textdark);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-box-orient: vertical;
}

.visitnamecontainer-image {
  display: block;
  opacity: 1;
  object-fit: cover;
}

.visitnamecontainer-visitname:hover .visitnamecontainer-image {
  display: block;
}

@media (max-width: 740px) {
  .visitnamecontainer-visitnamecontainer {
    justify-content: flex-end;
  }
}
