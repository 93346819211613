.unselectedtab-unselectedtab {
  gap: 10px;
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  opacity: 0.3;
  padding: 8px;
  position: relative;
  transition: 0.3s;
  align-items: center;
  padding-left: 8px;
}
.unselectedtab-unselectedtab:hover {
  cursor: pointer;
  opacity: 0.7;
}
.unselectedtab-image {
  display: none;
  object-fit: cover;
  display: none;
}
.unselectedtab-unselectedtab-disabled {
  gap: 10px;
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  opacity: 0.3;
  padding: 8px;
  position: relative;
  transition: 0.3s;
  align-items: center;
  padding-left: 8px;
}
.unselectedtab-unselectedtab-disabled:hover {
  cursor: not-allowed;
}
