.recordheader-recordheader {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 12px;
  flex-direction: column;
  justify-content: center;
}

.recordheader-visitname {
  gap: 10px;
  width: auto;
  height: auto;
  display: flex;
  opacity: 1;
  padding: 8px;
  transition: 0.3s;
  align-items: center;
  border-radius: 8px;
  justify-content: flex-start;
}

.recordheader-visitname:hover {
  cursor: pointer;
  /* opacity: 0.5; */
  /* background-color: var(--dl-color-halo-hovercolor); */
}

.recordheader-name {
  fill: var(--dl-color-halo-textdark);
  color: var(--dl-color-halo-textdark);
  opacity: 0.3;
}

.recordheader-image {
  display: flex;
  /* Hide pencil icon by default */
  object-fit: cover;
  transition: opacity 0.3s;
}

.recordheader-input {
  border: none;
  border-bottom: 1px solid var(--dl-color-halo-textdark);
  background: none;
  color: var(--dl-color-halo-textdark);
  font-size: 1em;
  outline: none;
  padding: 0 4px;
}

.recordheader-center-input {
  text-align: center;
}
