.frametranscript-frametranscript {
  gap: 10px;
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.frametranscript-container {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: scroll;
  /* mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0) 100%); */
  align-items: flex-start;
  flex-direction: column;
  /* -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0) 100%); */
}
.frametranscript-transcript {
  gap: 10px;
  flex: 1;
  display: flex;
  opacity: 1;
  align-items: flex-start;
  border-width: 0px;
  flex-direction: column;
}
.frametranscript-body {
  line-height: 24px;
}
