.togglecontainer-togglecontainer {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.togglecontainer-toggle {
  gap: 0px;
  flex: 0 0 auto;
  height: auto;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: center;
  padding-top: 4px;
  border-color: var(--dl-color-halo-bordercolor);
  border-width: 0px;
  padding-left: 4px;
  border-radius: 48px;
  padding-right: 4px;
  padding-bottom: 4px;
  justify-content: center;
  background-color: rgba(227, 227, 227, 0.4);
}
.togglecontainer-toggle:hover {
  cursor: pointer;
  background-color: var(--dl-color-halo-hovercolor);
}
