.iconlabel-iconlabel {
  gap: 10px;
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  opacity: 0.4;
  padding: 8px;
  position: relative;
  transition: 0.3s;
  align-items: center;
  padding-left: 8px;
}
.iconlabel-iconlabel:hover {
  cursor: pointer;
  opacity: 0.7;
}
.iconlabel-image {
  object-fit: cover;
}
