.login-container {
  width: 100%;
  display: flex;
  overflow: auto;
  max-height: 100vh;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: white;
}

.login-container1 {
  gap: 10px;
  flex: 0 0 auto;
  width: 496px;
  display: flex;
  padding: 48px;
  /* box-shadow: 14px 27px 45px 4px rgba(112, 144, 176, 0.2); */
  align-items: flex-start;
  border-color: var(--dl-color-halo-bordercolor);
  border-width: 0px;
  border-radius: 8px;
  flex-direction: column;
}

.login-text {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}

.login-text1 {
  font-size: 12px;
  margin-bottom: 12px;
}

.login-emailcontainer {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
}

.login-input {
  padding-left: 40px;
}

.login-image {
  top: 0px;
  left: 14px;
  bottom: 0px;
  margin: auto;
  opacity: 0.4;
  position: absolute;
  object-fit: cover;
}

.login-passwordcontainer {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
}

.login-input1 {
  padding-left: 40px;
}

.login-image1 {
  top: 0px;
  left: 14px;
  bottom: 0px;
  margin: auto;
  opacity: 0.4;
  position: absolute;
  object-fit: cover;
}

.darkbutton-root-class-name {
  width: 100%;
}

/* Media query for screens smaller than 600px */
@media (max-width: 740px) {
  .login-container1 {
    width: 90%;
    padding: 20px;
    box-shadow: none;
  }

  .login-text {
    font-size: 24px;
  }

  .login-text1 {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .login-input,
  .login-input1 {
    padding-left: 40px;
  }

  .login-image,
  .login-image1 {
    left: 14px;
  }
}
