.texteditor-textarea-wrapper {
  position: relative;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  opacity: 0.6; /* Default unfocused state */
}

.texteditor-textarea-wrapper:hover {
  opacity: 0.8; /* Hover state */
}

.texteditor-textarea-wrapper:focus-within {
  opacity: 1; /* Focused state */
}

.texteditor-textarea {
  flex: 1;
  width: 100%;
  height: 100%;
  resize: none;
  outline: none;
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: inherit;
  border: none;
  overflow: auto;
  color: transparent; /* Text color is transparent */
  caret-color: black; /* Cursor is visible */
  z-index: 1; /* Ensure textarea is above overlay */
  position: relative;
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.texteditor-textarea::-webkit-scrollbar {
  display: none; /* Hide scrollbar for WebKit browsers */
}

.texteditor-textarea::placeholder {
  opacity: 0.6;
  color: var(--dl-color-halo-textdark);
}

.texteditor-textarea-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none; /* Prevent interaction with the overlay */
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden; /* Prevent scrolling in the overlay */
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: inherit;
  scrollbar-width: none; /* Hide scrollbar for the overlay */
  color: inherit; /* Ensure text color matches textarea */
  z-index: 0; /* Place overlay below textarea */
}

.texteditor-textarea-overlay::-webkit-scrollbar {
  display: none; /* Hide scrollbar for overlay */
}

.texteditor-textarea-overlay span {
  pointer-events: none; /* Ensure highlighted spans don't block the cursor */
}

.highlight-blue {
  background-color: #e5f6fd;
  color: #074765;
}

.highlight-green {
  background-color: #edf7ed;
  color: #3e6241;
}

.highlight-red {
  background-color: #fdeded;
  color: #662b2a;
}

.highlight-purple {
  background-color: #f3edfd;
  color: #552a66;
}
