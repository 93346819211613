.selectednote-selectednote {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
}

.selectednote-notecontainer {
  gap: 10px;
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.selectednote-noteheader {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}

.selectednote-image {
  object-fit: cover;
}

.selectednote-image1 {
  object-fit: cover;
}

.selectednote-image2 {
  object-fit: cover;
}

.selectednote-body {
  line-height: 24px;
  width: 100%;
  height: auto;
  resize: none;
  border: none;
  outline: none;
  overflow: hidden;
  background-color: transparent;
}

.horizontal-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.row {
  display: flex;
  gap: 10px;
  width: 100%;
}

.mb-2 {
  margin-bottom: 10px;
}

@media (max-width: 740px) {
  .circleicondark-root-class-name1 {
    display: none;
  }
}

.circleicondark-root-class-name1 {
  display: none;
}
