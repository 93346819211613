.circleiconlight-circleiconlight {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  padding: 7px;
  position: relative;
  transition: 0.3s;
  align-items: flex-start;
  border-color: var(--dl-color-halo-bordercolor);
  border-width: 1px;
  border-radius: 50px;
}
.circleiconlight-circleiconlight:hover {
  cursor: pointer;
  background-color: var(--dl-color-halo-hovercolor);
}
.circleiconlight-root-class-name {
  opacity: 1;
}
