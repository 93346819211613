.microphonetestcontainer {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.microphonetestcontainer-header {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  gap: 10px;
}

.microphonetestcontainer-text {
  width: auto;
}

.microphonetestcontainer-bars {
  display: flex;
  align-items: center;
  gap: 4px;
}

.microphonetestcontainer-bar {
  width: 4px;
  height: 14px;
  background-color: #e0e0e0;
  border-radius: 2px;
}

.microphonetestcontainer-bar.active {
  background-color: var(--dl-color-halo-textdark);
}
