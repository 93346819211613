.blur-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  z-index: 998; /* Ensure it's below the FrameRecord */
}

.framerecord-framerecord {
  gap: 12px;
  flex: 0 0 auto;
  width: 280px;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 999;
}

.framerecord-blurcontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  flex: 1;
  left: 0px;
  bottom: 0px;
  position: absolute;
  opacity: 0.8;
  justify-content: center;
  border-radius: 8px;
  gap: 24px;
}

.backdrop-blur {
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 900; /* Ensure it covers the entire viewport */
  pointer-events: none; /* Prevent interactions with blurred content */
}

.framerecord-divider {
  width: 100%;
  height: 1px;
  background-color: var(--dl-color-halo-bordercolor);
}
