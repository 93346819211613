.frametcenter-frametcenter {
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
}

.frametcenter-templateitemlist {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: scroll;
  align-items: flex-start;
  flex-direction: column;
}
