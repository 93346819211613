.footer-footer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: center;
}
.footer-text {
  fill: var(--dl-color-halo-textlight);
  color: var(--dl-color-halo-textlight);
  opacity: 0.3;
  font-size: 10px;
  text-align: center;
}
.landscape-text {
  display: none;
}

@media (max-width: 740px) {
  .footer-hide {
    display: none;
  }
}
