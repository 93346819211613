.notetypecontainer-notetypecontainer {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
}

.notetypecontainer-text {
  width: auto;
}

.zero {
  width: 0px;
  height: 0px;
}

.notetypecontainer-dropdown {
  position: absolute;
  top: 0px;
  left: 40px;
}
