.tabbar-tabbar {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: grid;
  padding: 16px;
  position: relative;
  align-items: center;
  grid-template-columns: 1fr auto 1fr;
}

.tabbar-one {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.tabbar-two {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.tabbar-three {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}

.tabbar-templatelabel {
  fill: var(--dl-color-halo-textdark);
  color: var(--dl-color-halo-textdark);
  opacity: 0.3;
  padding: 4px;
}

.tabbar-templatelabel:hover {
  cursor: pointer;
  opacity: 0.5;
}

@media (max-width: 740px) {
  .tabbar-tabbar {
    padding: 10px;
    grid-template-columns: 1fr;
  }

  .tabbar-hide {
    display: none;
  }

  .tabbar-one {
    order: 2;
    justify-content: space-between;
  }

  .tabbar-two {
    order: 1;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .tabbar-three {
    display: none;
  }
}

.tabbar-username:hover {
  cursor: pointer !important;
  background-color: none !important;
  opacity: 0.5 !important;
}
