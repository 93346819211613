.unselectednote-unselectednote {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
}

.unselectednote-notecontainer {
  gap: 10px;
  flex: 1;
  display: flex;
  opacity: 0.6;
  transition: 0.3s;
  align-items: flex-start;
  border-width: 0px;
  flex-direction: column;
}

.unselectednote-notecontainer:hover {
  cursor: pointer;
  opacity: 0.7;
}

.unselectednote-noteheader {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}

.unselectednote-image {
  object-fit: cover;
}

.unselectednote-image1 {
  object-fit: cover;
}

.unselectednote-image2 {
  object-fit: cover;
}

.unselectednote-body {
  line-height: 24px;
  white-space: pre-line;
}

@media (max-width: 740px) {
  .circleicondark-root-class-name2 {
    display: none;
  }
}

.circleicondark-root-class-name2 {
  display: none;
}
