.recordingbutton-recordingbutton {
  gap: 10px;
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: center;
  padding-top: 14px;
  border-color: #ff4b4b;
  border-width: 1px;
  padding-left: 14px;
  border-radius: 50px;
  padding-right: 14px;
  padding-bottom: 14px;
  justify-content: center;
}
.recordingbutton-recordingbutton:hover {
  cursor: pointer;
  background-color: #fcf7f7;
}
.recordingbutton-text {
  fill: #ff4b4b;
  color: #ff4b4b;
}
