.dashboard-container {
  width: 100%;
  display: flex;
  overflow: auto;
  max-height: 100vh;
  min-height: 100vh;
  align-items: center;
  flex-direction: row;
  background-color: white;
}
.dashboard-container1 {
  flex: 1;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 8px;
  max-height: 100vh;
  min-height: 100vh;
  align-items: center;
  padding-left: 8px;
  flex-direction: column;
  gap: 10px;
}
.dashboard-frame {
  flex: 1;
  width: 60%;
  height: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  padding-top: 24px;
  flex-direction: column;
  padding-bottom: 24px;
  justify-content: center;
}

@media (max-width: 740px) {
  .dashboard-container {
    flex-direction: column;
  }

  .dashboard-container1 {
    height: 100%;
    padding: 20px;
  }

  .dashboard-frame {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
  }

  .sidebar-container,
  .dashboard-frame {
    display: none;
  }

  .show-sidebar .sidebar-container {
    display: flex;
  }

  .show-frame .dashboard-frame {
    display: flex;
  }

  .dashboard-hide {
    display: none;
  }
}
