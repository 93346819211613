.prompitem-prompitem {
  gap: 10px;
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  opacity: 0.3;
  padding: 8px;
  position: relative;
  transition: 0.3s;
  align-items: center;
  border-radius: 8px;
  background-color: #d9d9d9;
}
.prompitem-prompitem:hover {
  cursor: pointer;
  opacity: 0.5;
}
