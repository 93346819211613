.finishbutton-finishbutton {
  gap: 10px;
  background-color: var(--dl-color-halo-dark);
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: center;
  padding-top: 14px;
  border-color: var(--dl-color-halo-dark);
  border-width: 1px;
  padding-left: 14px;
  border-radius: 50px;
  padding-right: 14px;
  padding-bottom: 14px;
  justify-content: center;
}
.finishbutton-finishbutton:hover {
  cursor: pointer;
  opacity: 0.8;
}

.finishbutton-text {
  color: white;
}
