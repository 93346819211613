.subbutton-subbutton {
  gap: 10px;
  flex: 0 0 auto;
  height: auto;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: center;
  padding-top: 6px;
  border-color: var(--dl-color-halo-bordercolor);
  border-width: 1px;
  padding-left: 18px;
  border-radius: 8px;
  padding-right: 18px;
  padding-bottom: 6px;
  justify-content: center;
}
.subbutton-subbutton:hover {
  cursor: pointer;
  background-color: var(--dl-color-halo-hovercolor);
}
