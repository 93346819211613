.sidebarexternallinks-sidebarexternallinks {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: 8px;
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-halo-bordercolor);
  border-width: 1px;
  flex-direction: column;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
