.subbutton2-subbutton2 {
  gap: 10px;
  flex: 0 0 auto;
  height: auto;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: center;
  padding-top: 8px;
  border-color: var(--dl-color-halo-bordercolor);
  border-width: 1px;
  padding-left: 8px;
  border-radius: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  justify-content: center;
}

.subbutton2-subbutton2:hover {
  cursor: pointer;
  background-color: var(--dl-color-halo-hovercolor);
}

.subbutton2-image {
  width: 12px;
  height: 12px;
}

.subbutton2-text {
  font-size: 12px;
  display: none;
}
