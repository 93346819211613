.createnewtemplateitem-createnewtemplateitem {
  gap: 16px;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  transition: 0.3s;
  align-items: center;
  padding-top: 20px;
  border-color: var(--dl-color-halo-bordercolor);
  border-width: 0px;
  padding-left: 12px;
  padding-right: 12px;
  flex-direction: row;
  padding-bottom: 20px;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-radius: 8px;
}

.createnewtemplateitem-createnewtemplateitem:hover {
  cursor: pointer;
  background-color: var(--dl-color-halo-hovercolorlight);
}

.createnewtemplateitem-textcontainer {
  gap: 4px;
  flex: 1;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.createnewtemplateitem-text1 {
  font-size: 12px;
}

.circleiconlight-root-class-name2 {
  border-style: dashed;
}

.createnewtemplateitem-horizontalline {
  width: 100%;
  height: 1px;
  background-color: var(--dl-color-halo-bordercolor);
  margin-top: 2px;
  margin-bottom: 2px;
}
