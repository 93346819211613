.patientcontextcontainer-patientcontextcontainer {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.patientcontextcontainer-header {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  gap: 10px;
}
.patientcontextcontainer-text {
  width: auto;
}
.patientcontextcontainer-textareacontainer {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
}

/* .patientcontextcontainer-image {
  top: 12px;
  right: 12px;
  position: absolute;
  object-fit: cover;
} */
