.activetoggle-activetoggle {
  gap: 10px;
  flex: 0 0 auto;
  height: auto;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: center;
  padding-top: 10px;
  border-color: var(--dl-color-halo-bordercolor);
  border-width: 0px;
  padding-left: 18px;
  border-radius: 48px;
  padding-right: 18px;
  padding-bottom: 10px;
  justify-content: center;
  background-color: var(--dl-color-halo-dark);
}
.activetoggle-activetoggle:hover {
  cursor: pointer;
  /* opacity: 0.8; */
}
.activetoggle-text {
  fill: white;
  color: white;
  font-size: 14px;
}
