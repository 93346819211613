.selectedvisititem-selectedvisititem {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: 8px;
  position: relative;
  align-items: center;
  background-color: #ececec;
  border-radius: 8px;
  /* border-color: var(--dl-color-halo-bordercolor); */
  /* border-width: 1px; */
  /* border-radius: 8px; */
}

.selectedvisititem-visitheader {
  gap: 2px;
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.selectedvisititem-date {
  font-size: 12px;
}

.selectedvisititem-trash {
  object-fit: cover;
}
