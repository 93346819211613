.unselectedvisititem-unselectedvisititem {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  opacity: 0.3;
  padding: 8px;
  position: relative;
  transition: 0.3s;
  align-items: center;
  border-radius: 8px;
}

.unselectedvisititem-unselectedvisititem:hover {
  cursor: pointer;
  opacity: 0.7;
}

.unselectedvisititem-unselectedvisititem:hover .unselectedvisititem-trash {
  display: block;
}

.unselectedvisititem-visitheader {
  gap: 2px;
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.unselectedvisititem-date {
  font-size: 12px;
}

.unselectedvisititem-trash {
  display: none;
  object-fit: cover;
}

@media (max-width: 740px) {
  .unselectedvisititem-trash {
    display: block;
    object-fit: cover;
  }
}
