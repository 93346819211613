.sidebar-sidebar {
  flex: 0 0 auto;
  width: 260px;
  height: 100vh;
  display: flex;
  position: relative;
  max-height: 100vh;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
}

.sidebar-container {
  gap: 10px;
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 10px;
  overflow: hidden;
  align-items: flex-start;
  flex-direction: column;
}

.sidebar-header {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: 16px;
  padding-left: 8px;
  padding-right: 16px;
  padding-bottom: 0px;
}

.sidebar-image {
  height: 14px;
  margin-left: 8px;
  opacity: 1;
  object-fit: cover;
}

.sidebar-text {
  width: 100%;
  font-size: 24px;
  font-weight: 700;
}

.sidebar-recentlabel {
  fill: var(--dl-color-halo-medium);
  color: var(--dl-color-halo-medium);
  width: 100%;
  font-size: 10px;
  padding-top: 0px;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.sidebar-root-class-name1 {
  background-color: #f9f9f9;
}

.templatecenter-button {
  width: 100%;
}

.darkbutton-root-class-name2 {
  width: 100%;
}

@media (max-width: 740px) {
  .sidebar-sidebar {
    flex: 1;
    width: 100%;
    height: auto;
    min-height: auto;
    max-height: auto;
  }

  .sidebar-container {
    padding: 20px;
  }

  .sidebar-header {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .sidebar-image {
    height: 14px;
    margin-left: 10px;
  }

  .sidebar-text {
    font-size: 20px;
  }

  .sidebar-recentlabel {
    font-size: 10px;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
