.askaiinput-askaiinput {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 6px;
  border-color: var(--dl-color-halo-bordercolor);
  border-width: 1px;
  padding-left: 14px;
  border-radius: 50px;
  padding-right: 6px;
  padding-bottom: 6px;
}

.askaiinput-image {
  object-fit: cover;
}
