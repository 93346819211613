.diagnosesitem-diagnosesitem {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
}

.diagnosesitem-diagnosescontainer {
  gap: 10px;
  flex: 1;
  display: flex;
  opacity: 0.6;
  transition: 0.3s;
  align-items: flex-start;
  border-width: 0px;
  flex-direction: column;
  border-left: 0px solid #bfcbd1;
  border-radius: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
}

.diagnosesitem-diagnosescontainer:hover {
  opacity: 0.7;
  border-left: 0px solid #074765;
  cursor: pointer;
}

.diagnosesitem-diagnosescontainer-selected {
  gap: 10px;
  flex: 1;
  display: flex;
  opacity: 1;
  transition: 0.3s;
  align-items: flex-start;
  border-width: 0px;
  flex-direction: column;
  border-left: 0px solid #074765;
  border-radius: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
}

.circleiconlight-unselected {
  opacity: 0.7;
}

.diagnosesitem-diagnosesitem:hover .circleiconlight-unselected {
  opacity: 0.7;
}

.circleiconlight-root-class-name:hover {
  background-color: white;
}

.diagnosesitem-diagnosescontainer-selected:hover {
  cursor: pointer;
}

.diagnosesitem-itemheader {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}

.unselectednote-image {
  object-fit: cover;
}

.unselectednote-image1 {
  object-fit: cover;
}

.unselectednote-image2 {
  object-fit: cover;
}

.diagnosesitem-body {
  line-height: 24px;
  white-space: pre-line;
}

/* background-color: #e5f6fd;
  color: #074765; */
/* background-color: #d4edda !important;
  color: #155724 !important; */
