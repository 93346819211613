.downloads-container {
  width: 100%;
  display: flex;
  overflow: auto;
  max-height: 100vh;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  padding: 96px;
}

.downloads-title {
  width: 100%;
  padding: 12px;
  padding-bottom: 4px;
}

.downloads-subtitle {
  width: 100%;
  padding: 12px;
  padding-top: 0px;
}

.downloads-line {
  width: 100%;
  border-bottom: 1px solid lightgray;
}

.downloads-downloads {
  flex: 1;
  width: 80%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
}

.downloads-downloadsitemlist {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: scroll;
  align-items: flex-start;
  flex-direction: column;
}

.downloadsitem-downloadsitem {
  gap: 16px;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  transition: 0.3s;
  align-items: center;
  padding-top: 20px;
  padding-left: 12px;
  border-radius: 8px;
  padding-right: 12px;
  flex-direction: row;
  padding-bottom: 20px;
  justify-content: flex-start;
}

.downloadsitem-textcontainer {
  gap: 4px;
  flex: 4;
  width: 80%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.downloadsitem-text1 {
  font-size: 12px;
  align-content: vertical;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
}

.downloadsitem-visitusecontainer {
  gap: 6px;
  flex: 1;
  width: 20%;
  height: auto;
  display: none;
  opacity: 0.3;
  align-items: center;
  flex-direction: row;
}

.downloadsitem-image {
  width: 13px;
  height: 13px;
  object-fit: cover;
}

.downloadsitem-text2 {
  font-size: 12px;
}

.downloadsitem-buttoncontainer {
  gap: 10px;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}

.downloadsitem-statecontainer {
  gap: 4px;
  flex: 1;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.downloadsitem-statelabel {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: 4px;
  border-width: 1px;
  padding-left: 8px;
  border-radius: 8px;
  padding-right: 8px;
  padding-bottom: 4px;
  margin-right: 24px;
}
.downloadsitem-text3 {
  font-size: 12px;
}

.downloadsitem-notvisible-button {
  opacity: 0;
  cursor: none;
  pointer-events: none;
}

@media (max-width: 740px) {
  .downloadsitem-buttoncontainer {
    gap: 0px;
  }
}

.margin-offset {
  margin-top: 0px;
}

.disabled {
  pointer-events: none !important;
  cursor: not-allowed !important;
  opacity: 0.4;
}
