.dropdownoption-dropdownoption {
  gap: 6px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  opacity: 0.6;
  position: relative;
  transition: 0.3s;
  align-items: center;
  padding-top: 8px;
  padding-left: 12px;
  border-radius: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
}

.dropdownoption-dropdownoption:hover {
  cursor: pointer;
  opacity: 0.8;
  background-color: var(--dl-color-halo-hovercolor);
}

.dropdownoption-text {
  width: 100%;
}

.dropdownoption-image {
  object-fit: cover;
}
