.inactivetoggle-inactivetoggle {
  gap: 10px;
  flex: 0 0 auto;
  height: auto;
  display: flex;
  opacity: 0.3;
  position: relative;
  transition: 0.3s;
  align-items: center;
  padding-top: 6px;
  border-color: var(--dl-color-halo-bordercolor);
  border-width: 0px;
  padding-left: 18px;
  border-radius: 48px;
  padding-right: 18px;
  padding-bottom: 6px;
  justify-content: center;
}
.inactivetoggle-inactivetoggle:hover {
  cursor: pointer;
  opacity: 0.6;
}
.inactivetoggle-text {
  font-size: 14px;
}
