.darkbutton-darkbutton {
  gap: 10px;
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: center;
  padding-top: 14px;
  border-color: var(--dl-color-halo-bordercolor);
  border-width: 1px;
  padding-left: 48px;
  border-radius: 50px;
  padding-right: 48px;
  padding-bottom: 14px;
  justify-content: center;
  background-color: var(--dl-color-halo-dark);
}
.darkbutton-darkbutton:hover {
  cursor: pointer;
  opacity: 0.8;
}
.darkbutton-text {
  fill: white;
  color: white;
}
.darkbutton-root-class-name {
  margin-top: 12px;
}
.darkbutton-root-class-name1 {
  margin-top: 12px;
}
