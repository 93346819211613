.selectedtab-selectedtab {
  gap: 10px;
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  padding: 8px;
  position: relative;
  align-items: center;
  border-bottom: 1px solid var(--dl-color-halo-dark);
}
.selectedtab-image {
  object-fit: cover;
  display: none;
}
