.circleicondark-circleicondark {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  padding: 8px;
  position: relative;
  transition: 0.3s;
  align-items: flex-start;
  border-radius: 50px;
  background-color: var(--dl-color-halo-dark);
}
.circleicondark-circleicondark:hover {
  cursor: pointer;
  opacity: 0.8;
}

.circleicondark-root-class-name2 {
  opacity: 0;
}

.circleicondark-root-class-name2:hover {
  opacity: 0;
  cursor: auto;
}
