/* recordingcontainer.css */

.recordingcontainer-recordingcontainer {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  margin-top: 12px;
  align-items: flex-start;
  flex-direction: column;
  z-index: 998;
}

.recordingcontainer-startfinishcontainer {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}

.recordingcontainer-pausefinishcontainer {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
  cursor: not-allowed;
}

.disabled:hover {
  cursor: not-allowed;
  background-color: #e0e0e0;
}

.recordingcontainer-50button {
  width: 50%;
}
