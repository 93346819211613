.dropdown-dropdown {
  flex: 0 0 auto;
  width: 240px;
  height: auto;
  display: flex;
  padding: 8px;
  position: relative;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  align-items: flex-start;
  border-color: var(--dl-color-halo-bordercolor);
  border-width: 1px;
  border-radius: 8px;
  flex-direction: column;
  position: absolute;
  top: 46px;
  left: 0px;
  z-index: 999;
  background-color: white;
}
