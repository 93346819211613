.dropdownsection-dropdownsection {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}

.dropdownsection-divider {
  flex: 0 0 auto;
  width: 100%;
  height: 1px;
  display: flex;
  margin-top: 4px;
  align-items: flex-start;
  margin-bottom: 4px;
  background-color: var(--dl-color-halo-bordercolor);
}

.dropdownsection-header {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 8px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 8px;
}

.dropdownsection-label {
  fill: var(--dl-color-halo-textlight);
  color: var(--dl-color-halo-textlight);
  width: 100%;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}

.dropdownsection-image {
  opacity: 0.6;
}

.dropdownsection-image:hover {
  opacity: 0.8;
  cursor: pointer;
}

.dropdownsection-dropdownlist {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  overflow: scroll;
  max-height: 140px;
  align-items: flex-start;
  flex-direction: column;
}
